import React, { useCallback, useState } from "react";
import { styled } from "@mui/system";
import SignUpStepLayout from "./SignUpStepLayout";
import SignUpNextButton from "../forms/SignUpNextButton";

interface SignUpStep4Props {
  onNextStep: () => void;
}

const PlaneImage = styled("img")<{ shouldAnimate: boolean }>`
  position: fixed;
  height: 50px;
  display: ${(props) => (props.shouldAnimate ? "block" : "none")};
  animation: ${(props) => (props.shouldAnimate ? "takeOff 2s linear" : "none")};
  animation-fill-mode: forwards;

  @media (max-width: 600px) {
    display: none;
  }

  @keyframes takeOff {
    0% {
      left: 45%;
      bottom: 0;
      transform: rotate(0deg);
    }
    100% {
      left: 110%;
      bottom: 80%;
      transform: rotate(10deg);
    }
  }
`;

const SignUpStep4: React.FC<SignUpStep4Props> = ({ onNextStep }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  // New state variable for button disabled state
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleButtonClick = useCallback(() => {
    setButtonDisabled(true); // Disable button immediately when clicked
    const audioEl = new Audio("/audio/take-off.mp3");
    audioEl.volume = 0.2;
    audioEl.currentTime = 7;
    audioEl.play();

    setTimeout(() => {
      setShouldAnimate(true);
    }, 4000);

    setTimeout(onNextStep, 6000);
  }, [onNextStep]);

  return (
    <SignUpStepLayout title="Everything looks great! Welcome to the club.">
      {/* Pass the buttonDisabled state as a prop to SignUpNextButton.
          This assumes your SignUpNextButton component is implemented in a way that it will disable the button if this prop is true. */}
      <SignUpNextButton onClick={handleButtonClick} disabled={buttonDisabled} />

      <PlaneImage
        shouldAnimate={shouldAnimate}
        src="/images/plane-icon-3.png"
        alt="Plane taking off"
      />
    </SignUpStepLayout>
  );
};

export default SignUpStep4;

import { PollTwoTone } from "@mui/icons-material";

// Defining the type for the data structure used in saveStepData and getStepData
export interface StepData {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  phone_number?: string;
  date_of_birth?: string;
  code?: string;
  username?: string;
}

export const getCurrentStep = async (): Promise<string> => {
  // Replace this logic with actual logic to determine the step based on a cookie
  return "email";
};

let url_prefix = "https://roger-that-e17b66fe09c9.herokuapp.com/";
if (process.env.NODE_ENV === "development") {
  url_prefix = "http://127.0.0.1:8000/";
}

export const saveStepData = async (
  step: string,
  data: StepData
): Promise<{ success: boolean }> => {
  let url_body = "apiapp/users/";
  var post_or_patch = data.email ? "POST" : "PATCH";
  var appended_url = !data.email
    ? url_prefix + url_body + "me/"
    : url_prefix + url_body;
  try {
    // if the step data only contains a code, we're verifying the user with the code, make that call
  
    let response;
    if (!data.code) {
    response= await fetch(appended_url, {
      method: post_or_patch,
      headers:
        post_or_patch === "POST"
          ? { "Content-Type": "application/json" }
          : {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("atcAccess"),
            },
      body: JSON.stringify(data),
    });
  } else {
    console.log(data)
    response= await fetch(url_prefix + "apiapp/verify-otp/", {
      method: 'POST',
      headers:{
              "Content-Type": "application/json",
            },
      body: JSON.stringify(data),
    });
  }
    

    if (response.ok) {
      console.log("User created successfully!");
      if (post_or_patch === "POST" || (data.code && !data.email)) {
        console.log("doing something with the response");
        var d = await response.json();
        localStorage.setItem("atcRefresh", d.refresh);
        localStorage.setItem("atcAccess", d.access);
      }

      // if there is a username, set the username
      if (post_or_patch === "PATCH" && data.username) {
        console.log("set acct username");
        await localStorage.setItem("accountUsername", data.username as string);
      }
    } else {
      // it's possible the user already exists -- that's ok. that should put them in the authentication flow -- we'll just want to send the code to their phone
      // the json looks like { "email": [ "user with this email already exists." ] }
      var d = await response.json();
      if (d.email && d.email[0] === "user with this email already exists.") {
        console.log("Error creating the user!");

        if (data.email) {
          await localStorage.setItem("accountEmail", data.email as string);
          await localStorage.setItem("userWasPreviouslyCreated", "true");
        }

        // send code to phone (they are trying to log in)
        fetch(url_prefix + "apiapp/request-otp/", {
          method: "POST",
          body: JSON.stringify({ email: data.email }),
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle any network or other error here
  }
  return { success: true };
};

export const getStepData = async (step: string): Promise<StepData> => {
  // Replace this with actual retrieval logic

  var url = url_prefix + "apiapp/users/";
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      console.log("User created successfully!");
      // Handle any successful response here
    } else {
      console.log("Error creating the user!");
      // Handle any error response here
    }
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle any network or other error here
  }

  // Example data
  return {
    first_name: "John",
    last_name: "Doe",
    email: "John@doe.com",
    password: "pw",
    username: "batman",
  };
};

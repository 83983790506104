import React, { useState, useEffect } from "react";
import { getCurrentStep } from "./signUpApi";

/* Steps */
import SignUpStep1 from "./SignUpStep1";
import SignUpStep2 from "./SignUpStep2";
import SignUpStep3 from "./SignUpStep3";
import SignUpStep4 from "./SignUpStep4";

const SignUpFlowWrapper: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<string | null>(null);

  useEffect(() => {
    const loadCurrentStep = async () => {
      const step: string = await getCurrentStep();
      setCurrentStep(step);
    };
    loadCurrentStep();
  }, []);

  const goToNextStep = () => {
    let nextStep;
    if (currentStep === "email") {
        nextStep = "code";
    } else if (currentStep === "code") {
      if (localStorage.getItem("userWasPreviouslyCreated")) {
        nextStep = null;
      } else {
        nextStep = "username";
      }
    } else if (currentStep === "username") {
      nextStep = "final";
    } else {
      nextStep = null;
    }
    setCurrentStep(nextStep);

    if (nextStep === null) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div>
      {currentStep === "email" && <SignUpStep1 onNextStep={goToNextStep} />}
      {currentStep === "code" && <SignUpStep2 onNextStep={goToNextStep} />}
      {currentStep === "username" && <SignUpStep3 onNextStep={goToNextStep} />}
      {currentStep === "final" && <SignUpStep4 onNextStep={goToNextStep} />}
    </div>
  );
};

const LoginFlowWrapper: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<string | null>(null);

  useEffect(() => {
    const loadCurrentStep = async () => {
      const step: string = await getCurrentStep();
      setCurrentStep(step);
    };
    loadCurrentStep();
  }, []);

  const goToNextStep = () => {
    let nextStep;
    if (currentStep === "email") {
      nextStep = "code";
    } else {
      nextStep = null;
    }
    setCurrentStep(nextStep);

    if (nextStep === null) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <div>
      {currentStep === "email" && <SignUpStep1 onNextStep={goToNextStep} />}
      {currentStep === "code" && <SignUpStep4 onNextStep={goToNextStep} />}
      {/* redirect to home page if currentStep is null, use react router */}
      {/* Render other step components based on currentStep */}
    </div>
  );
};

const FlowWrapper: React.FC = () => {
  // if there is an accountEmail in local storage, render LoginFlowWrapper else render SignUpFlowWrapper
  let userWasPreviouslyCreated = localStorage.getItem(
    "userWasPreviouslyCreated"
  );

  return (
    <div>
      {userWasPreviouslyCreated ? <LoginFlowWrapper /> : <SignUpFlowWrapper />}
    </div>
  );
};

//export default SignUpFlowWrapper;
export default FlowWrapper;
